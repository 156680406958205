import React from "react";

const Cta = (props) => {
  return (
    <section id="section__cta">
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-md-4">
            <div className="cta__title">
              <h2 className="mb-3 mt-3">
                Speed up your world with {props.name}
              </h2>
              <a href="tel:+18007126792" className="plan-btn-dark">
                Call Now
              </a>
            </div>
          </div>
          <div className="col-12 col-md-8 col-lg-8">
            <div className="container">
              <div className="box">
                <div className="column text-center" id="column1">
                  <div className="icon" id="item1">
                    <img src="/assets/imgs/icons/film.png" alt="film" />
                  </div>
                  <div className="icon" id="item3">
                    <img src="/assets/imgs/icons/badge-hd.png" alt="badge" />
                  </div>
                </div>
                <div className="column text-center" id="column2">
                  <div className="icon" id="item2">
                    <img
                      src="/assets/imgs/icons/collection-play.png"
                      alt="collection-play"
                    />
                  </div>
                  <div className="icon" id="item4">
                    <img
                      src="/assets/imgs/icons/controller.png"
                      alt="controller"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cta;
