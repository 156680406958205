import React, { Fragment } from "react";

const Providersection = () => {
  return (
    <Fragment>
      <section className="package__area pt-130">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="sec-title-wrap">
                <h2
                  className="sec-sub-title wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.2s"
                >
                  Offerings provide
                </h2>
                <h3
                  className="sec-title wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.4s"
                >
                  Largest Providers <br />
                  <span>For You.</span>
                </h3>
              </div>
            </div>
            <div className="brand__area">
              <div className="container">
                <div className="row">
                  <div className="col-xxl-12">
                    <div className="brand__list">
                      <div
                        className="brand__item wow animate__animated animate__fadeInUp"
                        data-wow-delay="0.15s"
                      >
                        <a href="providers/at&amp;t">
                          <img
                            src="/assets/imgs/brand/10.png"
                            alt="Brand Logo"
                            loading="lazy"
                          />
                        </a>
                      </div>
                      <div
                        className="brand__item wow animate__animated animate__fadeInUp"
                        data-wow-delay="0.3s"
                      >
                        <a href="providers/spectrum">
                          {" "}
                          <img
                            src="/assets/imgs/brand/4.png"
                            alt="Spectrum"
                            loading="lazy"
                          />{" "}
                        </a>
                      </div>
                      <div
                        className="brand__item wow animate__animated animate__fadeInUp"
                        data-wow-delay="0.45s"
                      >
                        <a href="providers/xfinity">
                          {" "}
                          <img
                            src="/assets/imgs/brand/3.png"
                            alt="Xfinity"
                            loading="lazy"
                          />{" "}
                        </a>
                      </div>
                      <div
                        className="brand__item wow animate__animated animate__fadeInUp"
                        data-wow-delay="0.6s"
                      >
                        <a href="providers/directv">
                          {" "}
                          <img
                            src="/assets/imgs/brand/11.png"
                            alt="DIRECTV"
                            loading="lazy"
                          />{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row pt-100">
                  <div className="col-xxl-12">
                    <div className="brand__list">
                      <div
                        className="brand__item wow animate__animated animate__fadeInUp"
                        data-wow-delay="0.3s"
                      >
                        <a href="providers/frontier">
                          {" "}
                          <img
                            src="/assets/imgs/brand/2.png"
                            alt="Frontier"
                            loading="lazy"
                          />{" "}
                        </a>
                      </div>
                      <div
                        className="brand__item wow animate__animated animate__fadeInUp"
                        data-wow-delay="0.45s"
                      >
                        <a href="providers/t-mobile">
                          {" "}
                          <img
                            src="/assets/imgs/brand/T-mobile.png"
                            alt="T-mobile"
                            loading="lazy"
                          />{" "}
                        </a>
                      </div>
                      <div
                        className="brand__item wow animate__animated animate__fadeInUp"
                        data-wow-delay="0.6s"
                      >
                        <a href="providers/centurylink">
                          {" "}
                          <img
                            src="/assets/imgs/brand/1.png"
                            alt="CenturyLink"
                            loading="lazy"
                          />{" "}
                        </a>
                      </div>
                      <div
                        className="brand__item wow animate__animated animate__fadeInUp"
                        data-wow-delay="0.75s"
                      >
                        <a href="providers/hughesnet">
                          {" "}
                          <img
                            src="/assets/imgs/brand/5.png"
                            alt="Brand Logo"
                            loading="lazy"
                          />{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row pt-100">
                  <div className="col-xxl-12">
                    <div className="brand__list">
                      <div
                        className="brand__item wow animate__animated animate__fadeInUp"
                        data-wow-delay="0.3s"
                      >
                        <a href="#">
                          <img
                            src="/assets/imgs/brand/6.png"
                            alt="CenturyLink"
                            loading="lazy"
                          />
                        </a>
                      </div>
                      <div
                        className="brand__item wow animate__animated animate__fadeInUp"
                        data-wow-delay="0.45s"
                      >
                        <a href="providers/verizon">
                          <img src="/assets/imgs/brand/7.png" alt="Verizon" />
                        </a>
                      </div>
                      <div
                        className="brand__item wow animate__animated animate__fadeInUp"
                        data-wow-delay="0.6s"
                      >
                        <a href="#">
                          <img
                            src="assets/imgs/brand/12.png"
                            alt="cox"
                            loading="lazy"
                          />
                        </a>
                      </div>
                      <div
                        className="brand__item wow animate__animated animate__fadeInUp"
                        data-wow-delay="0.75s"
                      >
                        <a href="providers/viasat">
                          <img
                            src="assets/imgs/brand/9.png"
                            alt="Viasat"
                            loading="lazy"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Providersection;
