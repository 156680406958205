import React, { Fragment } from "react";

const Modal = () => {
  return (
    <Fragment>
      {/* <!-- Modal --> */}
      <div
        className="modal fade modal__wkprocess"
        id="workProcessModal2"
        tabIndex="-1"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <button className="modal-close" data-bs-dismiss="modal">
              <i className="fa-solid fa-xmark"></i>
            </button>
            <div className="modal-body"></div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Modal;
