import React, { Fragment } from "react";

const Sectionthree = () => {
  return (
    <Fragment>
      {/* <!-- Service area start --> */}
      <section className="service__area-2 pt-130">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="sec-title-wrap">
                {/* <h2
                  className="sec-sub-title wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.2s"
                >
                  Our Best Service
                </h2> */}
                <h3
                  className="sec-title wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.4s"
                >
                  The Services You Can Find
                  <br />
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
              <div
                className="service__item-2 wow animate__animated animate__fadeInUp"
                data-wow-delay="0.15s"
              >
                <div className="service__top-2">
                  <div className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="33.878"
                      height="33.875"
                      viewBox="1225.061 2061.062 33.878 33.875"
                    >
                      <g data-name="004-support">
                        <path
                          d="M33.852 5.916c-.144-1.637-2.132-2.366-3.296-1.203l-1.662 1.662a.976.976 0 0 1-1.36.014c-.392-.378-.372-1.029.014-1.415l1.635-1.635c1.162-1.162.436-3.15-1.203-3.295-4.454-.392-7.987 3.75-6.806 8.144L.94 28.422a3.204 3.204 0 0 0 0 4.534 3.21 3.21 0 0 0 4.534 0l20.229-20.23c4.34 1.181 8.545-2.315 8.149-6.81Z"
                          fillRule="evenodd"
                          fill='url("#a")'
                          transform="translate(1225.061 2061.044)"
                          data-name="Path 85"
                        />
                        <path
                          d="M4.311 10.055c.048.093.11.179.184.253l2.872 2.872a.992.992 0 0 0 1.403 0l1.461-1.46 2.79 2.79-2.983 2.981a1.989 1.989 0 0 0 .001 2.807l.313.313 10.179-10.178-.313-.313a1.985 1.985 0 0 0-2.808 0l-2.986 2.985-2.79-2.789 1.461-1.46a.992.992 0 0 0 0-1.404L10.223 4.58a.99.99 0 0 0-.256-.186L6.296 2.55a.992.992 0 0 0-1.148.185L2.64 5.244a.992.992 0 0 0-.183 1.15l1.854 3.66Z"
                          fillRule="evenodd"
                          fill='url("#b")'
                          transform="translate(1222.867 2058.778)"
                          data-name="Path 86"
                        />
                        <path
                          d="m265.214 258.046-7.065 7.065 8.114 8.116a4.993 4.993 0 0 0 7.064 0 5 5 0 0 0 0-7.065l-8.113-8.116Zm5.814 12.88a.993.993 0 0 1-1.403 0l-5.634-5.634a.992.992 0 1 1 1.404-1.403l5.633 5.633a.992.992 0 0 1 0 1.404Z"
                          fillRule="evenodd"
                          fill='url("#c")'
                          transform="translate(983.993 1820.09)"
                          data-name="Path 87"
                        />
                      </g>
                      <defs>
                        <linearGradient x1=".5" y1="0" x2=".5" y2="1" id="a3">
                          <stop stopColor="#f50366" offset="0" />
                          <stop stopColor="#fc5904" offset="1" />
                        </linearGradient>
                        <linearGradient x1=".5" y1="0" x2=".5" y2="1" id="b3">
                          <stop stopColor="#f50366" offset="0" />
                          <stop stopColor="#fc5904" offset="1" />
                        </linearGradient>
                        <linearGradient x1=".5" y1="0" x2=".5" y2="1" id="c3">
                          <stop stopColor="#f50366" offset="0" />
                          <stop stopColor="#fc5904" offset="1" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  <h3 className="service__title">Cable</h3>
                </div>
                <div className="service__btm-2 text-justify">
                  <p className="text-justify">
                    Broadband Movers help you find the best affordable cable
                    service in your area. Whether it’s Internet, TV, or Home
                    Phone, we are here to assist you in look for reliable and
                    long-lasting service for entertainment purpose or business
                    needs from top U.S providers Comcast Xfinity, Charter
                    Spectrum, and Cox. Our cable internet relies on telecom
                    infrastructure for optimal efficiency. Count on us to make
                    finding high-quality cable providers easier.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
              {/* <!--<a href="service-2.html">--> */}
              <div
                className="service__item-2 wow animate__animated animate__fadeInUp"
                data-wow-delay="0.3s"
              >
                <div className="service__top-2">
                  <div className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="33.871"
                      height="33.878"
                      viewBox="1228.9 2394.54 33.871 33.878"
                    >
                      <g data-name="003-copywriting">
                        <path
                          d="M16.214 399.359V392H8.789l7.425 7.359Z"
                          fillRule="evenodd"
                          fill='url("#a")'
                          transform="translate(1220.693 2028.478)"
                          data-name="Path 95"
                        />
                        <path
                          d="m221.609 221.609 1.403 4.211.703.703 2.806-2.808-.702-.702-4.21-1.404Z"
                          fillRule="evenodd"
                          fill='url("#b")'
                          transform="translate(1021.955 2187.595)"
                          data-name="Path 96"
                        />
                        <path
                          d="M0 0h3.97v13.278H0V0Z"
                          fillRule="evenodd"
                          fill='url("#c")'
                          transform="rotate(-45 3539.33 -297.58) scale(.99985)"
                          data-name="Path 97"
                        />
                        <path
                          d="M0 0h3.97v2.978H0V0Z"
                          fillRule="evenodd"
                          fill='url("#d")'
                          transform="rotate(-45 3557.75 -305.21) scale(.99985)"
                          data-name="Path 98"
                        />
                        <path
                          d="M25.872 20.258V0H0v23.953h9.991v9.925h15.88v-2.392l-11.54-11.539-2.806-8.423 8.421 2.809 5.926 5.925ZM8.006 3.044h13.896v1.985H8.006V3.044Zm-3.97 0h1.985v1.985H4.036V3.044Zm0 5.955V7.014h17.866v1.985H4.036Z"
                          fillRule="evenodd"
                          fill='url("#e")'
                          transform="translate(1228.9 2394.54)"
                          data-name="Path 99"
                        />
                      </g>
                      <defs>
                        <linearGradient x1=".5" y1="0" x2=".5" y2="1" id="a">
                          <stop stopColor="#f50366" offset="0" />
                          <stop stopColor="#fc5904" offset="1" />
                        </linearGradient>
                        <linearGradient x1=".5" y1="0" x2=".5" y2="1" id="b">
                          <stop stopColor="#f50366" offset="0" />
                          <stop stopColor="#fc5904" offset="1" />
                        </linearGradient>
                        <linearGradient x1=".5" y1="0" x2=".5" y2="1" id="c">
                          <stop stopColor="#f50366" offset="0" />
                          <stop stopColor="#fc5904" offset="1" />
                        </linearGradient>
                        <linearGradient x1=".5" y1="0" x2=".5" y2="1" id="d">
                          <stop stopColor="#f50366" offset="0" />
                          <stop stopColor="#fc5904" offset="1" />
                        </linearGradient>
                        <linearGradient x1=".5" y1="0" x2=".5" y2="1" id="e">
                          <stop stopColor="#f50366" offset="0" />
                          <stop stopColor="#fc5904" offset="1" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  <h3 className="service__title">Satellite</h3>
                </div>
                <div className="service__btm-2 text-justify">
                  <p className="text-justify">
                    If you are in a rural area and finding best internet
                    services we are here to help you find faster speeds and more
                    reliable connections. Search for the top-notch Satellite
                    providers like Hughes Net, and Viasat Internet explore the
                    competitive Internet, TV, and Home Phone plans customized
                    for your needs. Discover high-quality internet services to
                    ensure that customers experience best data transmission.
                  </p>
                </div>
              </div>
              {/* <!--</a>--> */}
            </div>

            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
              <div
                className="service__item-2 wow animate__animated animate__fadeInUp"
                data-wow-delay="0.45s"
              >
                <div className="service__top-2">
                  <div className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="33.878"
                      height="33.878"
                      viewBox="425.061 2061.061 33.878 33.878"
                    >
                      <g data-name="vector">
                        <g data-name="Group 68">
                          <g data-name="Group 67">
                            <path
                              d="M32.885 0H28.85a.992.992 0 0 0-.992.993v.992h-8.123A2.973 2.973 0 0 0 16.939 0a2.973 2.973 0 0 0-2.795 1.985H6.021V.993A.992.992 0 0 0 5.03 0H.993A.992.992 0 0 0 0 .993v3.97c0 .548.444.992.993.992h4.036a.992.992 0 0 0 .992-.992V3.957h4.076C6.653 6.109 4.382 9.888 4.11 14.12a2.972 2.972 0 0 0-2.059 2.818 2.98 2.98 0 0 0 2.978 2.978 2.98 2.98 0 0 0 2.977-2.978 2.976 2.976 0 0 0-1.915-2.773c.34-4.72 3.632-8.697 8.213-9.832a2.97 2.97 0 0 0 2.635 1.621 2.97 2.97 0 0 0 2.635-1.621c4.58 1.135 7.874 5.113 8.212 9.832a2.976 2.976 0 0 0-1.914 2.773 2.98 2.98 0 0 0 2.977 2.978 2.98 2.98 0 0 0 2.978-2.978c0-1.32-.868-2.428-2.06-2.818-.27-4.233-2.542-8-5.987-10.15h4.077v.992c0 .548.444.992.992.992h4.036a.992.992 0 0 0 .993-.992V.993A.992.992 0 0 0 32.885 0Z"
                              fillRule="evenodd"
                              fill='url("#a")'
                              transform="translate(425.061 2061.061)"
                              data-name="Path 88"
                            />
                          </g>
                        </g>
                        <g data-name="Group 70">
                          <g data-name="Group 69">
                            <path
                              d="M166.842 453.805c-.519-1.588-2.015-2.805-3.775-2.805h-5.955c-1.76 0-3.256 1.217-3.775 2.805-.2.613.321 1.231.966 1.231h11.574c.644 0 1.165-.618.965-1.23Z"
                              fillRule="evenodd"
                              fill='url("#b")'
                              transform="translate(281.91 1639.903)"
                              data-name="Path 89"
                            />
                          </g>
                        </g>
                        <g data-name="Group 72">
                          <g data-name="Group 71">
                            <path
                              d="m138.698 143.12-7.773-11.661v9.416a2.973 2.973 0 0 1 1.985 2.795 2.98 2.98 0 0 1-2.977 2.977 2.98 2.98 0 0 1-2.978-2.977c0-1.293.832-2.384 1.985-2.795v-9.416l-7.773 11.66a.992.992 0 0 0 .124 1.252c1.822 1.823 2.986 4.17 3.436 6.688a5.91 5.91 0 0 1 2.228-.442h5.955c.789 0 1.539.162 2.228.442.45-2.518 1.614-4.865 3.436-6.688a.992.992 0 0 0 .124-1.252Z"
                              fillRule="evenodd"
                              fill='url("#c")'
                              transform="translate(312.067 1938.3)"
                              data-name="Path 90"
                            />
                          </g>
                        </g>
                        <g data-name="Group 74">
                          <g data-name="Group 73">
                            <path
                              d="M241.993 301a.993.993 0 1 0 .001 1.986.993.993 0 0 0-.001-1.986Z"
                              fillRule="evenodd"
                              fill='url("#d")'
                              transform="translate(200.007 1779.978)"
                              data-name="Path 91"
                            />
                          </g>
                        </g>
                      </g>
                      <defs>
                        <linearGradient x1=".5" y1="0" x2=".5" y2="1" id="a6">
                          <stop stopColor="#f50366" offset="0" />
                          <stop stopColor="#fc5904" offset="1" />
                        </linearGradient>
                        <linearGradient x1=".5" y1="0" x2=".5" y2="1" id="b6">
                          <stop stopColor="#f50366" offset="0" />
                          <stop stopColor="#fc5904" offset="1" />
                        </linearGradient>
                        <linearGradient x1=".5" y1="0" x2=".5" y2="1" id="c6">
                          <stop stopColor="#f50366" offset="0" />
                          <stop stopColor="#fc5904" offset="1" />
                        </linearGradient>
                        <linearGradient x1=".5" y1="0" x2=".5" y2="1" id="d6">
                          <stop stopColor="#f50366" offset="0" />
                          <stop stopColor="#fc5904" offset="1" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  <h3 className="service__title">Fiber</h3>
                </div>
                <div className="service__btm-2 text-justify">
                  <p className="text-justify">
                    Find the best connectivity at the optimum speed with Fiber
                    Service and enjoy your lag-free games, watch Netflix, and
                    stream 4K videos anytime, anywhere with high reliability and
                    low latency. From the best-known fiber provider like Verizon
                    Fios, EarthLink Fiber, AT&T Fiber, and Frontier, experience
                    seamless internet and telecom broadcast. With our
                    exceptional suggestions you can say goodbye to buffering and
                    hello to an excellent connectivity.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Service area end --> */}
    </Fragment>
  );
};

export default Sectionthree;
