import React, { Fragment } from "react";
// import logo from '../assets/imgs/logo/logo.png';
// import './master.css';
// import '../assets/css/bootstrap.min.css';
// import '../assets/css/meanmenu.min.css';
// import '../assets/css/progressbar.css';
// import '../assets/css/swiper-bundle.min.css';

// import '../assets/js/bootstrap.bundle.min.js';

const Footer = () => {
  return (
    <Fragment>
      <section className="newsletter__area">
        <div className="container">
          <div className="newsletter__inner">
            <div className="row">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                <div
                  className="newsletter__content wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.2s"
                >
                  <h2 className="title">Disclaimer</h2>
                  <p>
                    The information provided on this website is not affiliated
                    with any internet providers. We are an independent company
                    that provides information about internet providers and
                    services. We do not endorse or recommend any particular
                    internet provider. The information on this website is for
                    informational purposes only and should not be used as a
                    substitute for professional advice.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className="footer__area-2">
        <div className="footer__top">
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="footer__widgets">
                  <div className="widget__one">
                    <img
                      src="/assets/imgs/logo/logo.png"
                      alt="Footer Logo"
                      className="footer__logo"
                    />
                    <p>
                      Broadbandmovers.com has collaborated of some of the most
                      well-known cable TV and broadband providers in your region
                      to capture the best coverage offerings available. Insert
                      the zip code to visualize the Internet Service Providers
                      (ISPs) and television providers are available in your
                      city, and then compare cable, telecommunication, and
                      satellite providers side by side
                    </p>

                    <ul className="social-media">
                      <li>
                        <a href="https://www.facebook.com/Broadbandmovers/">
                          <i className="fa-brands fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://twitter.com/Broadbandmover2">
                          <i className="fa-brands fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa-brands fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.linkedin.com/company/broadbandmovers/">
                          <i className="fa-brands fa-linkedin"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="footer__widget-two">
                    <h2 className="footer__widget-title">My Account</h2>
                    <ul className="footer__menu">
                      <li>
                        <a href="/privacy-policy-terms-and-conditions">
                          Privacy Policy
                        </a>
                      </li>
                      <li>
                        <a href="/states">States</a>
                      </li>
                      <li>
                        <a href="/about">About</a>
                      </li>
                      <li>
                        <a href="/contact">Contact</a>
                      </li>
                      <li>
                        <a href="https://www.broadbandmovers.com/resource">
                          Resource
                        </a>
                      </li>
                      <li>
                        <a href="/info-request">
                          Do Not Sell My Personal Information
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="footer__widget-three">
                    <h2 className="footer__widget-title">Providers</h2>
                    <ul className="footer__menu">
                      <li>
                        <a href="/providers/at&t">AT&T</a>
                      </li>
                      <li>
                        <a href="/providers/spectrum">Spectrum</a>
                      </li>
                      <li>
                        <a href="/providers/hughesnet">HughesNet</a>
                      </li>
                      <li>
                        <a href="/providers/earthlink">EarthLink</a>
                      </li>
                      <li>
                        <a href="/providers/xfinity">Xfinity</a>
                      </li>
                      <li>
                        <a href="/providers/t-mobile">T-Mobile</a>
                      </li>
                    </ul>
                  </div>
                  <div className="footer__widget-four">
                    <h2 className="footer__widget-title">Newsletter</h2>
                    <p>
                      Subscribe to our newsletter for timely information and
                      valuable insights.
                    </p>
                    <div className="newsletter">
                      <form>
                        <input type="text" placeholder="Enter your email" />
                        <button type="submit" className="submit">
                          SUBSCRIBE
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer__btm-2">
          <p>
            © 2023 — <a href="https://broadbandmovers.com">Broadbandmovers</a>.
            All Rights Reserved.
          </p>
        </div>
      </footer>
    </Fragment>
  );
};

export default Footer;
