import React from "react";

const Brand = () => {
  return (
    <>
      {/* <!-- Brand area start --> */}
      <section className="brand__area">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="brand__list">
                <div className="brand__item">
                  <a href="/providers/hughesnet">
                    {" "}
                    <img src="/assets/imgs/brand/5.png" alt="HughesNet" />{" "}
                  </a>
                </div>
                <div className="brand__item">
                  <a href="/providers/at&amp;t">
                    <img src="/assets/imgs/brand/10.png" alt="AT&T" />
                  </a>
                </div>
                <div className="brand__item">
                  <a href="/providers/t-mobile">
                    {" "}
                    <img
                      src="/assets/imgs/brand/T-mobile.png"
                      alt="T-mobile"
                    />{" "}
                  </a>
                </div>
                <div className="brand__item">
                  <a href="/providers/spectrum">
                    {" "}
                    <img src="/assets/imgs/brand/4.png" alt="Spectrum" />{" "}
                  </a>
                </div>
                <div className="brand__item">
                  <a href="/providers/frontier">
                    {" "}
                    <img src="/assets/imgs/brand/2.png" alt="Brand Logo" />{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Brand area end --> */}
    </>
  );
};

export default Brand;
