import React, { Fragment } from "react";

const Cities = () => {
  return (
    <>
      <section className="service__area">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="sec-title-wrap">
                <h2
                  className="sec-sub-title wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.2s"
                >
                  High-Speed Internet in the U.S
                </h2>
                <h3
                  className="sec-title wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.4s"
                >
                  Cities With the Fastest Internet <br />
                  <span>For You.</span>
                </h3>
              </div>
            </div>

            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
              <a href="/cities/los-angeles">
                <div
                  className="service__item wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.4s"
                >
                  <div className="service__right">
                    <h3 className="service__title"> Los Angeles </h3>
                    <p>Internet Providers</p>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 text-right-sm">
              <a href="/cities/chicago">
                <div
                  className="service__item wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.4s"
                >
                  <div className="service__right">
                    <h3 className="service__title"> Chicago </h3>
                    <p>Internet Providers</p>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
              <a href="/cities/new-york">
                <div
                  className="service__item wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.4s"
                >
                  <div className="service__right">
                    <h3 className="service__title"> New-York </h3>
                    <p>Internet Providers</p>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 text-right-sm">
              <a href="/cities/dallas">
                <div
                  className="service__item wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.4s"
                >
                  <div className="service__right">
                    <h3 className="service__title">Dallas</h3>
                    <p>Internet Providers</p>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
              <a href="/cities/phoenix">
                <div
                  className="service__item wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.4s"
                >
                  <div className="service__right">
                    <h3 className="service__title">Phoenix</h3>
                    <p>Internet Providers</p>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 text-right-sm">
              <a href="/cities/san-antonio">
                <div
                  className="service__item wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.4s"
                >
                  <div className="service__right">
                    <h3 className="service__title">San Antonio</h3>{" "}
                    <p>Internet Providers</p>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
              <a href="/cities/seattle">
                <div
                  className="service__item wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.4s"
                >
                  <div className="service__right">
                    <h3 className="service__title">Seattle</h3>{" "}
                    <p>Internet Providers</p>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 text-right-sm">
              <a href="/cities/san-francisco">
                <div
                  className="service__item wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.4s"
                >
                  <div className="service__right">
                    <h3 className="service__title">San Francisco</h3>{" "}
                    <p>Internet Providers</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Cities;
