const {
  FETCH_STATE,
  FETCH_FIVE_CITY,
  FETCH_CITIES,
  FETCH_STATE_CITY,
  SET_LOADING,
} = require("../utils/types");

const initialState = {
  data: null,
  fiveCities: null,
  cities: null,
  location: false,
  loading: false,
};

export const stateReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STATE:
      return {
        ...state,
        data: action.payload,
      };
    case FETCH_FIVE_CITY:
      return {
        ...state,
        fiveCities: action.payload,
      };
    case FETCH_CITIES:
      return {
        ...state,
        cities: action.payload,
      };
    case FETCH_STATE_CITY:
      return {
        ...state,
        location: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};
