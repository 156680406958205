import axios from "axios";
import {
  ERROR,
  FETCH_PROVIDER,
  FETCH_PROVIDER_CITY,
  FETCH_PROVIDER_ZIP,
  SET_LOADING,
} from "../utils/types";
export const getProviderDetails = (name) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await axios.get(
      `https://server.broadbandmovers.com/providers/${name}`
    );
    dispatch({
      type: FETCH_PROVIDER,
      payload: response.data,
    });
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch({
      type: ERROR,
      payload: error,
    });
  }
};

export const setLoading = (value) => (dispatch) => {
  dispatch({
    type: SET_LOADING,
    payload: value,
  });
};
export const getProviderbyCity = (city) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await axios.get(
      `https://server.broadbandmovers.com/providers/city/${city}`
    );
    dispatch({
      type: FETCH_PROVIDER_CITY,
      payload: response.data,
    });
    dispatch(setLoading(false));
  } catch (error) {
    dispatch({
      type: ERROR,
      payload: error,
    });
  }
};

export const getProviderbyZip = (zip) => async (dispatch) => {
  try {
    const response = await axios.get(
      `https://server.broadbandmovers.com/search/${zip}`
    );
    dispatch({
      type: FETCH_PROVIDER_ZIP,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR,
      payload: error,
    });
  }
};
