const {
  FETCH_PROVIDER,
  FETCH_PROVIDER_CITY,
  FETCH_PROVIDER_ZIP,
  SET_LOADING,
} = require("../utils/types");

const initialState = {
  data: null,
  loading: false,
};

export const providerReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROVIDER:
      return {
        ...state,
        data: action.payload,
        loading: true,
      };
    case FETCH_PROVIDER_CITY:
      return {
        ...state,
        data: action.payload,
      };
    case FETCH_PROVIDER_ZIP:
      return {
        ...state,
        data: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};
