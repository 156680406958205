import axios from "axios";
import {
  ERROR,
  FETCH_CITIES,
  FETCH_FIVE_CITY,
  FETCH_STATE,
  FETCH_STATE_CITY,
  SET_LOADING,
} from "../utils/types";
export const getAllStates = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await axios.get(
      "https://server.broadbandmovers.com/state"
    );
    const data = await response.data;
    dispatch({
      type: FETCH_STATE,
      payload: data,
    });
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch({
      type: ERROR,
      payload: error,
    });
  }
};

export const getStateInfo = (state) => async (dispatch) => {
  try {
    const response = await axios.get(
      `https://server.broadbandmovers.com/state/${state}/info`
    );
    const data = await response.data;
    dispatch({
      type: FETCH_STATE,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ERROR,
      payload: error,
    });
  }
};

export const getTopCities = (state) => async (dispatch) => {
  try {
    const response = await axios.get(
      `https://server.broadbandmovers.com/state/${state}`
    );
    const data = await response.data;
    dispatch({
      type: FETCH_FIVE_CITY,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ERROR,
      payload: error,
    });
  }
};

export const getCities = (state) => async (dispatch) => {
  try {
    const response = await axios.get(
      `https://server.broadbandmovers.com/state/${state}/cities`
    );
    const data = await response.data;
    dispatch({
      type: FETCH_CITIES,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ERROR,
      payload: error,
    });
  }
};

export const getCityAndState = (city) => async (dispatch) => {
  try {
    const response = await axios.get(
      `https://server.broadbandmovers.com/providers/city/${city}/details`
    );
    const data = await response.data;
    dispatch({
      type: FETCH_STATE_CITY,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ERROR,
      payload: error,
    });
  }
};

export const getCityAndStateZip = (zip) => async (dispatch) => {
  try {
    const response = await axios.get(
      `https://server.broadbandmovers.com/search/state/${zip}`
    );
    const data = await response.data;
    dispatch({
      type: FETCH_STATE_CITY,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ERROR,
      payload: error,
    });
  }
};

export const setLoading = (value) => (dispatch) => {
  dispatch({
    type: SET_LOADING,
    payload: value,
  });
};
