import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { getProviderDetails } from "../actions/providerActions";
import Headerthree from "./common/Headerthree";
import { Helmet } from "react-helmet-async";
import Footer from "./common/Footer";
import LoadingSpinner from "./ui/LoadingSpinner";

const Provider = (props) => {
  let { name } = useParams();
  const [code, setCode] = useState("");
  const navigate = useNavigate();
  const capitalName = name.charAt(0).toUpperCase() + name.slice(1);
  name = name.toUpperCase();
  const [width, Setwidth] = useState(window.innerWidth <= 768);
  let plans;
  let content;
  let paragraph;
  let providerContent;
  let title;
  let description;
  if (
    name === "AT&T" ||
    name === "SPECTRUM" ||
    name === "FRONTIER" ||
    name === "EARTHLINK" ||
    name === "XFINITY" ||
   
    name === "CENTURYLINK"
  ) {
    title = `${capitalName} | fast internet speed | broadbandmovers`;
    providerContent = `In today’s interconnected world, a reliable and high-speed internet connection is not just a luxury – it's a necessity. Welcome to ${capitalName}, your trusted partner in delivering cutting-edge solutions for your digital needs. ${capitalName}’s array of internet plans caters to every requirement, from high-speed internet to the robust reliability of DSL internet and the lightning-fast speeds of Fiber Optic Internet. Say goodbye to buffering and hello to seamless connectivity for streaming, gaming, and browsing, all backed by advanced network infrastructure because they understand that your digital lifestyle encompasses more than just the internet.
    That's why ${capitalName} offers bundled services that seamlessly combine high-speed internet with crystal-clear home phone services and a wide array of TV services. Whether you're a fan of cable TV, satellite TV, or crave the nostalgia of home phones telephone – have a solution that suits you. Immerse yourself in a world of entertainment with Streaming Services. Dive into on-demand content, from the latest shows to blockbuster movies, all at your fingertips, and with robust services, your entertainment options know no bounds.
    At ${capitalName}, you don't just get services – you get a complete experience. ${capitalName}’s commitment to exceptional customer service ensures that your digital journey is hassle-free and enjoyable, and are always here to answer your questions, solve any issues, and make sure you get the most out of your connection. Join the ranks of satisfied customers who have embraced the power of ${capitalName}.
    Subscribe us today to explore the best internet plans, wireless services, bundled services, streaming services, and more. Step into the future of connectivity with ${capitalName}, where your digital aspirations become a reality!`;
  } else if (name === "HUGHESNET" || name === "VIASAT") {
    title = `${capitalName} | High-speed Satellite Internet|broadbandmovers`;
    providerContent = `In today's digital age, being connected is more essential than ever. Enter ${capitalName} also known as the best internet service provider your gateway to an unparalleled online experience that brings the world to your fingertips. Their diverse range of internet plans ensures that you stay connected at lightning speed. From ${capitalName}’s high-speed internet options to robust satellite internet, all got you covered. Say goodbye to slow loading times and buffering videos – with ${capitalName}, you'll enjoy seamless streaming, smooth browsing, and uninterrupted downloads.
    But ${capitalName} don't stop at just internet. The bundled services combine the power of high-speed connectivity with crystal-clear home phone services and a variety of TV services. Whether you're a fan of satellite TV or you're into streaming services, the bundled offerings provide a one-stop solution for all your telecommunications needs. Speaking of entertainment, the streaming services open the doors to a world of on-demand content. Dive into your favorite shows, movies, and more without any interruptions. it's like having a theater right in your living room!
    At ${capitalName}, you don't just get services – you get a complete experience. ${capitalName}’s commitment to exceptional customer service ensures that your digital journey is hassle-free and enjoyable, and are always here to answer your questions, solve any issues, and make sure you get the most out of your connection. Join the ranks of satisfied customers who have embraced the power of ${capitalName}.
    Subscribe today to explore the best internet plans, bundled services, streaming services, and more. elevate your online experience and unlock a world of limitless possibilities with ${capitalName}. Your journey to seamless connectivity begins here.`;
  } else if (name === "DIRECTV") {
    title = `${capitalName} | Best Cable TV Providers | broadbandmovers`;
    providerContent = `Get ready to embark on an entertainment journey like never before with ${capitalName}, your ultimate destination for exceptional TV services. As a best internet service provider (ISP), they bring you a comprehensive range of offerings that will redefine the way you enjoy television.
    Their diverse range of service plans ensures that you have access to blazing-fast high-speed internet and robust wireless services, setting the stage for an uninterrupted streaming and browsing experience. But that's just the beginning – TV services take your entertainment to new heights.
    With bundled services, you can enjoy the perfect fusion of high-speed internet, crystal-clear home phone services, and a selection of TV services that cater to all your preferences. Choose between cable TV and satellite TV, each offering an extensive array of channels and immersive content. And let's not forget about streaming services – your gateway to a world of on-demand entertainment. Whether you're in the mood for binge-watching your favorite series or catching the latest blockbuster, the streaming options have got you covered.
    ${capitalName} go the extra mile to ensure your satisfaction. ${capitalName}’s commitment to customer service means that you'll have a seamless and hassle-free experience. Their dedicated team is always ready to assist you, answer your queries, and provide the support you need.
    Upgrade your TV experience with ${capitalName}. Contact today, to explore the service plans, bundled services, streaming services, and more. Join the ranks of satisfied customers who have embraced a new era of TV entertainment and connectivity. Your journey to a world of enriched TV experiences begins here.
    `;
  } else if (name === "VERIZON" || name === "T-MOBILE") {
    title = `${capitalName} | best wifi service | broadbandmovers`;
    providerContent = `Step into a world of cutting-edge technology and unparalleled connectivity with ${capitalName}, your ultimate destination for 5G Home Internet and exceptional mobile services. As a trusted internet service provider, ${capitalName} is here to redefine your digital experience, offering a comprehensive suite of services designed to empower your connectivity and mobility. Their internet plans are tailored to suit your unique needs, ensuring that you have access to lightning-fast high-speed internet and robust wireless services, whether you're at home or on the go.
    Stay connected, stream your favorite content, and browse without interruptions. But the offerings extend beyond just the internet. With the best bundled services, you can enjoy a seamless integration of high-speed internet, crystal-clear home phone services, and an array of video streaming options. Experience the future of entertainment with streaming services – your gateway to on-demand content that puts you in control of your viewing experience. Whether you're binge-watching your favorite series or discovering new movies, the streaming options cater to all your entertainment preferences.
    At ${capitalName},  your satisfaction is prioritize. ${capitalName}’s commitment to customer service ensures that your experience with us is smooth, efficient, and tailored to your needs. The dedicated team is here to assist you, answer your inquiries, and provide the support you deserve. Elevate your connectivity and mobility with ${capitalName}.
    Subscribe today to explore the best internet plans, bundled services, streaming services, and more. join the ranks of satisfied customers who have embraced a new era of seamless connectivity and entertainment, both at home and on the go. Your journey to enriched digital experiences begins here.
    `;
  } else {
    providerContent = null;
  }

  if (
    name === "HUGHESNET" ||
    name === "VIASAT" ||
    name === "T-MOBILE" ||
    name === "VERIZON"
  ) {
    content = `Stay Fast, Stay Connected with ${capitalName}`;
  } else if (name === "AT&T" || name === "DIRECTV") {
    content = `${capitalName} Your Fast Track to Connectivity`;
  } else {
    content = `Speed up your world with ${capitalName}`;
  }

  if (name === "DIRECTV") {
    paragraph = `Experience the best quality TV service with ${capitalName}, discover the high resolution of video and movie streaming in best budget. Entertain yourself with cable TV or Satellite TV. Whether you are at home or on the go, stay connected with best seamless connectivity of high-speed internet`;
    description = `Experience the best quality TV service with ${capitalName}, discover the high resolution of video and movie streaming in best budget`;
  } else if (name === "HUGHESNET" || name === "VIASAT") {
    paragraph = `Welcome to the new era of digital world with ${capitalName}’s cutting-edge satellite internet. With state-of-art satellite technology you can get smooth streaming services and high-speed internet service for rural areas as well, plus bundle plans that includes built-in Wi-Fi and seamless connectivity, so you can watch more and use less. Stay connected to everything you love.`;
    description = `Welcome to the new era of digital world with ${capitalName}’s cutting-edge satellite internet`;
  } else if (name === "VERIZON" || name === "T-MOBILE") {
    paragraph = `Introducing ${capitalName}’s revolutionary 5G home internet and mobile service. with 5G mobile service, you can work remotely from anywhere, entertain and play with seamless connectivity home and on the move. experience high-speed internet data, best bundle services and home internet solutions. Don’t miss out on the future of connectivity.`;
    description = `Introducing ${capitalName}’s revolutionary 5G home internet and mobile service. with 5G mobile service, you can work remotely from anywhere`;
  } else if (name === "AT&T") {
    paragraph = `With the ${capitalName}, you'll get high-speed internet plans and internet services tailored to your needs. You can finally enjoy seamless connectivity with high-speed fiber-internet, plus also offers high-quality streaming services, wireless service, bundle plans and home phone solutions, so you can stay connected to everything.`;
    description = `With the ${capitalName}, you'll get high-speed internet plans and internet services tailored to your needs`;
  } else {
    paragraph = `With the ${capitalName}, you'll get high-speed internet plans and internet services tailored to your needs. You can finally enjoy seamless connectivity with high-speed fiber-internet, plus also offers high-quality streaming services, wireless service, bundle plans and home phone solutions, so you can stay connected to everything.`;
    description = `With the ${capitalName}, you'll get high-speed internet plans and internet services tailored to your needs`;
  }

  useEffect(() => {
    props.getProviderDetails(name);
  }, [name]);

  const { data, loading } = props.provider;
  const { error } = props.error;

  const isFetchInProgress = loading && data === null;

  useEffect(() => {
    if (isFetchInProgress) {
      return; // Wait until the fetch is complete
    }

    if (error && !loading) {
      navigate("/not-found");
    }
  }, [error, loading]);

  if (data) {
    plans = data.map((item, index) => {
      let description = item.des.replace(/<br>/g, "");
      return (
        <div
          className="package__type-2 starter wow animate__ animate__fadeInUp animated"
          data-wow-delay="0.2s"
          style={{
            visibility: "visible",
            animationDelay: "0.2s",
            animationName: "fadeInUp",
          }}
          key={index}
        >
          <div className="package__top-2">
            <div className="package__title-wrap">
              <h4 className="package__title">{item.name}</h4>
            </div>
            <div className="package__price-2">
              <span className="monthly_price">
                {item.price.replace(/\/mo/g, "")}
                <span className="type">/month</span>
              </span>
              <span className="yearly_price">
                {item.price.replace(/\/mo/g, "")}
                <span className="type">/month</span>
              </span>
            </div>
          </div>
          <div className="package__feature-2">
            <ul className="list-check-2">{description}</ul>

            <a href="tel:+18007126792" className="cxu-btn-round-dark">
              Get A Free Qoute
            </a>
          </div>
        </div>
      );
    });
  } else if (!data && !error) {
    plans = <LoadingSpinner />;
  }

  const inputHandler = (e) => {
    setCode(e.target.value);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    navigate(`/search/${code}`);
  };
  return (
    <>
      <Helmet>
        <title> {title} </title>
        <meta name="description" content={description} />
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Headerthree />
      {/* <!-- Breadcrumb area start --> */}
      <section className="breadcrumb__area">
        <div className="container-fluid">
          <div className="row align-items-center justify-content-around">
            <div className="col-12 col-md-7">
              <div class="jumbotron jumpbox">
                <div className="providerws">
                  <div className="breadcrumb__content providers">
                    <h1
                      className="breadcrumb__title wow animate__ animate__fadeInUp animated"
                      data-wow-delay="0.2s"
                      style={{
                        visibility: "visible",
                        animationDelay: "0.2s",
                        animationName: "fadeInUp",
                        color: "#ff4904",
                        fontSize: "2.5rem",
                      }}
                    >
                      {content}
                    </h1>
                    <br />

                    <p>{paragraph}</p>
                    <br />
                    <hr class="my-4"></hr>
                  </div>
                </div>
                <div className="row align-items-center justify-content-center">
                  <div className="col-12 col-md-6">
                    <h3 className="p-2">FIND YOUR PERFECT PROVIDER</h3>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="newsletter__form">
                      <div
                        className="newsletter wow animate__animated animate__fadeInUp"
                        data-wow-delay="0.4s"
                      >
                        <form onSubmit={submitHandler}>
                          <input
                            type="text"
                            maxLength={5}
                            placeholder="Enter zip code"
                            name="zipcode"
                            onChange={inputHandler}
                          />
                          <button type="submit" className="submit">
                            SEARCH <br /> PROVIDER
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="jumbotron jumpbox">
                <div className="row align-items-center justify-content-between text-center">
                  <div className="col-12 col-md-12">
                    <div className="breadcrumb__content providers">
                      <h2
                        className="breadcrumb__title wow animate__ animate__fadeInUp animated m-2"
                        data-wow-delay="0.2s"
                        style={{
                          visibility: "visible",
                          animationDelay: "0.2s",
                          animationName: "fadeInUp",
                          color: "#ff4904",
                          fontSize: "2.5rem",
                        }}
                      >
                        <span style={{ fontSize: "0.9rem" }}>UPTO</span> 50% OFF
                        GET NOW
                      </h2>
                    </div>
                  </div>
                  <div className="col-12 col-md-12 m-2 text-center">
                    {" "}
                    <a href="tel:+18007126792" className="plan-btn">
                      +1 (800) 712 6792
                    </a>
                  </div>
                  <div className="col-12 col-md-12 m-2">
                    <img
                      className="wow animate__animated animate__fadeInUp brandlogo"
                      src={`/assets/imgs/brand/${capitalName}.png`}
                      data-wow-delay="0.2s"
                      alt={capitalName}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Breadcrumb area end --> */}

      <section className="service__area-2 pb-130 pt-130">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
              <div className="sec-title-wrap">
                <h2
                  className="sec-sub-title wow animate__ animate__fadeInUp animated"
                  data-wow-delay="0.2s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.2s",
                    animationName: "fadeInUp",
                  }}
                >
                  Internet plans
                </h2>
                <h3
                  className="sec-title wow animate__ animate__fadeInUp animated"
                  data-wow-delay="0.4s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.2s",
                    animationName: "fadeInUp",
                  }}
                >
                  {capitalName} internet Plans For You.
                </h3>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xxl-12">
              <div className="package__list">{plans}</div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- About area start --> */}
      <section className="service__area-2  pb-130 pt-130">
        <div className="events">
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="sec-title-wrap">
                  <h2
                    className="sec-sub-title wow animate__ animate__fadeInUp animated"
                    data-wow-delay="0.2s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.2s",
                      animationName: "fadeInUp",
                    }}
                  >
                    provider Bundels
                  </h2>
                  <h3
                    className="sec-title wow animate__ animate__fadeInUp animated"
                    data-wow-delay="0.4s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.4s",
                      animationName: "fadeInUp",
                    }}
                  >
                    Best Bundle Deals For <br />{" "}
                    <span> TV, Internet and Phone </span>!{" "}
                  </h3>
                </div>
              </div>
              <div className="col-xxl-12">
                <div className="package__list">
                  <div className="package__type-2 premium wow animate__ animate__fadeInUp animated">
                    <div className="package__top-2">
                      <div className="package__title-wrap">
                        <h4 className="package__title">Value Bundle</h4>
                        <div className="best-choice">300 Mbps</div>
                      </div>
                      <div className="package__price-2">
                        <strong>$</strong>
                        <span className="monthly_price">
                          59.99<span className="type">/month</span>
                        </span>
                        <span className="yearly_price">
                          59
                          <span className="type">/month</span>
                        </span>
                      </div>
                    </div>

                    <div className="package__feature-2">
                      <ul className="list-check-2">
                        <li>300 Mbps Upload & Download</li>
                        <li>No Data Caps No Contract</li>
                        <li>No Installation Fees</li>
                      </ul>
                      <a href="tel:+18007126792" className="cxu-btn-round-dark">
                        Get A Free Qoute
                      </a>
                    </div>
                  </div>

                  <div
                    className="package__type-2 standard wow animate__ animate__fadeInUp animated"
                    data-wow-delay="0.4s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.2s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="package__top-2">
                      <div className="best-choice">500 Mbps</div>
                      <div className="package__title-wrap">
                        <h4 className="package__title">Big savings</h4>
                      </div>
                      <div className="package__price-2">
                        <strong>$</strong>
                        <span className="monthly_price">
                          74.99<span className="type">/month</span>
                        </span>
                        <span className="yearly_price">
                          199
                          <span className="type">/year</span>
                        </span>
                      </div>
                    </div>
                    <div className="package__feature-2">
                      <ul className="list-check-2">
                        <li>500 Mbps Upload & Download</li>
                        <li>No Data Caps No Contract</li>
                        <li>No Installation Fees</li>
                      </ul>
                      <a href="tel:+18007126792" className="cxu-btn-round-2">
                        Get A Free Qoute
                      </a>
                    </div>
                  </div>
                  <div
                    className="package__type-2 premium wow animate__ animate__fadeInUp animated"
                    data-wow-delay="0.6s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.6s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="package__top-2">
                      <div className="package__title-wrap">
                        <div className="best-choice">1000 Mbps</div>
                        <h4 className="package__title">Combo Deal</h4>
                      </div>
                      <div className="package__price-2">
                        <strong>$</strong>
                        <span className="monthly_price">
                          89.99<span className="type">/month</span>
                        </span>
                        <span className="yearly_price">
                          459
                          <span className="type">/year</span>
                        </span>
                      </div>
                    </div>
                    <div className="package__feature-2">
                      <ul className="list-check-2">
                        <li>1000 Mbps Upload & Download</li>
                        <li>No Data Caps No Contract</li>
                        <li>No Installation Fees</li>
                      </ul>
                      <a href="tel:+18007126792" className="cxu-btn-round-dark">
                        Get A Free Qoute
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- About area end --> */}
      <section className="about__area-2 pt-130 pb-130">
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
              <div className="about__left-3">
                <img src="/assets/imgs/hero/phone.jpg" alt="Internet Speed" />
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
              <div className="about__right-2">
                <div className="about__right-inner-2">
                  <h3
                    className="sec-title wow animate__ animate__fadeInUp animated"
                    data-wow-delay="0.4s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.2s",
                      animationName: "fadeInUp",
                    }}
                  >
                    Empowering Entertainment, Elevating Connectivity <br /> Your
                    Journey Starts Here
                  </h3>

                  <p
                    className="wow animate__ animate__fadeInUp text-justify animated"
                    data-wow-delay="0.5s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.5s",
                      animationName: "fadeInUp",
                    }}
                  >
                    {providerContent ? (
                      providerContent
                    ) : (
                      <>
                        In today’s interconnected world, a reliable and
                        high-speed internet connection is not just a luxury –
                        it's a necessity. Welcome to {capitalName}, your trusted
                        partner in delivering cutting-edge solutions for your
                        digital needs. Our array of internet plans caters to
                        every requirement, from high-speed internet to the
                        robust reliability of DSL internet and the
                        lightning-fast speeds of Fiber Optic Internet. Say
                        goodbye to buffering and hello to seamless connectivity
                        for streaming, gaming, and browsing, all backed by our
                        advanced network infrastructure. We understand that your
                        digital lifestyle encompasses more than just internet.{" "}
                        <br />
                        That's why we offer bundled services that seamlessly
                        combine high-speed internet with crystal-clear home
                        phone services and a wide array of TV services. Whether
                        you're a fan of cable TV, satellite TV, or crave the
                        nostalgia of home phones telephone, we have a solution
                        that suits you. Immerse yourself in a world of
                        entertainment with our Streaming Services. Dive into
                        on-demand content, from the latest shows to blockbuster
                        movies, all at your fingertips. With our robust
                        services, your entertainment options know no bounds.{" "}
                        <br />
                        At {capitalName}, we don't just provide services; we
                        provide an experience. Our commitment to exceptional
                        customer service ensures that your digital journey is
                        smooth and hassle-free. Our team is dedicated to
                        resolving any query or concern, making your experience
                        with us truly outstanding. Join the league of satisfied
                        customers who have embraced the convenience, speed, and
                        reliability of {capitalName}. <br />
                        Subscribe us today to explore our internet plans,
                        wireless
                      </>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="service__area-2 pt-130">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="sec-title-wrap">
                <h3
                  className="sec-title wow animate__ animate__fadeInUp animated"
                  data-wow-delay="0.4s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.4s",
                    animationName: "fadeInUp",
                  }}
                >
                  Why Choose {capitalName}? PROVIDER.
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
              <div
                className="service__item-2 wow animate__ animate__fadeInUp animated"
                data-wow-delay="0.15s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.15s",
                  animationName: "fadeInUp",
                }}
              >
                <div className="service__top-22">
                  <h3 className="service__title">1. Blazing-Fast Speeds</h3>
                </div>
                <div className="service__btm-2">
                  <p>
                    Elevate your online activities with speeds of up to 1 Gbps,
                    ensuring seamless streaming, gaming, and downloads.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
              <div
                className="service__item-2 wow animate__ animate__fadeInUp animated"
                data-wow-delay="0.3s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.3s",
                  animationName: "fadeInUp",
                }}
              >
                <div className="service__top-22">
                  <h3 className="service__title">2. Reliable Connectivity</h3>
                </div>
                <div className="service__btm-2">
                  <p>
                    Say goodbye to interruptions – {capitalName} Fiber provides
                    a consistent and dependable connection, keeping you
                    connected no matter where you are.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
              <div
                className="service__item-2 wow animate__ animate__fadeInUp animated"
                data-wow-delay="0.45s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.45s",
                  animationName: "fadeInUp",
                }}
              >
                <div className="service__top-22">
                  <h3 className="service__title">3. Comprehensive Package</h3>
                </div>
                <div className="service__btm-2">
                  <p>
                    Experience the convenience of bundled services, including
                    high-speed internet, landline phones, and TV options,
                    tailored to suit your preferences.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    provider: state.provider,
    error: state.error,
  };
};

export default connect(mapStateToProps, { getProviderDetails })(Provider);
