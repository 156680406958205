import React, { Fragment } from "react";

const Sectionone = () => {
  return (
    <Fragment>
      <section className="feature__area-2">
        <div className="container">
          <div className="feature__inner-2">
            <div className="row">
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3">
                <div
                  className="feature__item-2 wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.15s"
                >
                  <img
                    src="assets/imgs/feature/8.png"
                    alt="Icon"
                    className="feature__icon"
                  />
                  <h2 className="feature__text-2">Top Internet Providers</h2>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3">
                <div
                  className="feature__item-2 wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.3s"
                >
                  <img
                    src="assets/imgs/feature/5.png"
                    alt="Icon"
                    className="feature__icon"
                  />
                  <h2 className="feature__text-2">Best Cable TV Providers </h2>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3">
                <div
                  className="feature__item-2 wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.45s"
                >
                  <img
                    src="assets/imgs/feature/6.png"
                    alt="Icon"
                    className="feature__icon"
                  />
                  <h2 className="feature__text-2">
                    Best Satellite & Fiber TV{" "}
                  </h2>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3">
                <div
                  className="feature__item-2 wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.6s"
                >
                  <img
                    src="assets/imgs/feature/7.png"
                    alt="Icon"
                    className="feature__icon"
                  />
                  <h2 className="feature__text-2">Best Wifi Service </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Sectionone;
