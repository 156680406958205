import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { sendEmail } from "../actions/formActions";
import toast, { Toaster } from "react-hot-toast";

const Sectionfive = (props) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    num: "",
    zip: "",
    adres: "",
  });
  const { form } = props.form;
  const condStyle =
    props.show === true ? { color: "white" } : { display: "none" };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (form === "Data submitted successfully") {
      toast.success("We anticipate contacting you shortly.", {
        duration: 4000,
        style: {
          backgroundColor: "#ff4904",
          color: "#fff",
        },
      });
    }
  }, [props.form]);

  const handleSubmit = (e) => {
    e.preventDefault();
    props.sendEmail(formData);
    formData.name = "";
    formData.email = "";
    formData.num = "";
    formData.adres = "";
    formData.zip = "";
  };
  return (
    <Fragment>
      {/* <!-- Hire area start --> */}
      <section className="hire__area-2">
        <Toaster />
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-xxl-12">
              <div
                className="hire__content contact__form"
                style={{ backgroundColor: "transparent" }}
              >
                <h2
                  className="hire__title wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.2s"
                >
                  Get Internet Providers Near You
                </h2>
              </div>
            </div>
            {/* <div className={props.show ? "col-md-4" : "col-md-3"}>
              <div className="hire__content">
                <h3 style={condStyle}>
                  Check accessibility, to connect services in your area
                </h3>
              </div>
            </div> */}
            <div className="col-md-8">
              <form onSubmit={handleSubmit}>
                <div className="row justify-content-center">
                  <div className="col-md-6">
                    <div
                      className="hire__content contact__form"
                      style={{ backgroundColor: "transparent" }}
                    >
                      <input
                        type="text"
                        placeholder="Your Name*"
                        name="name"
                        required
                        value={formData.name}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div
                      className="hire__content contact__form"
                      style={{ backgroundColor: "transparent" }}
                    >
                      <input
                        type="text"
                        placeholder="Your Email*"
                        name="email"
                        required
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div
                      className="hire__content contact__form"
                      style={{ backgroundColor: "transparent" }}
                    >
                      <input
                        type="text"
                        placeholder="Your Phone num"
                        name="num"
                        required
                        value={formData.num}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div
                      className="hire__content contact__form"
                      style={{ backgroundColor: "transparent" }}
                    >
                      <input
                        type="text"
                        placeholder="Your Zipcode"
                        name="zip"
                        required
                        value={formData.zip}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div
                      className="hire__content contact__form"
                      style={{ backgroundColor: "transparent" }}
                    >
                      <input
                        type="text"
                        placeholder="Your Address"
                        name="adres"
                        required
                        value={formData.adres}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="hire__content ">
                      <button
                        type="submit"
                        className="cxu-btn-border wow animate__animated animate__fadeInUp text-white"
                        data-wow-delay="0.6s"
                        style={{ backgroundColor: "transparent" }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <img
          src="/assets/imgs/hero/overlay.png"
          alt="overlay"
          className="overlay"
          loading="lazy"
        />
      </section>
      {/* <!-- Hire area end --> */}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    form: state.form,
  };
};

export default connect(mapStateToProps, { sendEmail })(Sectionfive);
