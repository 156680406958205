import React from "react";

const Breadcrumb = () => {
  return (
    <>
      {/* <!-- Breadcrumb area start --> */}
      <section className="breadcrumb__area">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="breadcrumb__content">
                <h1
                  className="breadcrumb__title wow animate__animated animate__fadeInUp text-center"
                  data-wow-delay="0.2s"
                >
                  First Step to Proceed!
                </h1>
                <h3 className="text-white text-center">
                  Select your{" "}
                  <span style={{ color: "rgb(255, 73, 4)" }}>state</span> from
                  below:
                </h3>
                {/*<a href="/">Home</a>
                  <span>About Us</span> */}
              </div>
            </div>
          </div>
        </div>
        <img
          src="/assets/imgs/hero/overlay.png"
          alt="overlay"
          className="overlay"
        />
      </section>
      {/* <!-- Breadcrumb area end --> */}
    </>
  );
};

export default Breadcrumb;
