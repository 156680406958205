import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router";
import Header from "./common/Header";
import Sectionone from "./Sectionone";
import Sectiontwo from "./Sectiontwo";
import Sectionthree from "./Sectionthree";
import Sectionfour from "./Sectionfour";
import Sectionfive from "./Sectionfive";
import States from "./States";
import Modal from "./ui/Modal";
import Providersection from "./Providersection";
import Cities from "./Cities";
import Cta from "./common/Cta";
import Footer from "./common/Footer";
import { Helmet } from "react-helmet-async";
// import image from "../../public/assets/imgs/hero/overlay.png"
const Section = () => {
  const [code, setCode] = useState("");
  const navigate = useNavigate();
  const title = `Internet Connectivity in Your Area | Broadbandmovers`;
  const inputHandler = (e) => {
    setCode(e.target.value);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    navigate(`/search/${code}`);
  };
  return (
    <Fragment>
      <Helmet>
        <title>{title}</title>
        <meta
          name="description"
          content="Browse the perfect seamless internet connectivity in your area just by entering your area’s zip code and also check the availability of Top-Notch Internet Service Providers."
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Header />
      <section className="hero__area-2">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="hero__content">
                <h1 className="hero__title" style={{ fontSize: "60px" }}>
                  Search All Providers
                </h1>
                <h4 style={{ color: "#fff" }}>
                  Search Top Cable, Internet Providers In Your Area
                </h4>
              </div>
            </div>
            <div className="col-md-3">
              <div className="hero__content">
                <div className="newsletter__form">
                  <div
                    className="newsletter wow animate__animated animate__fadeInUp"
                    data-wow-delay="0.4s"
                  >
                    <form onSubmit={submitHandler}>
                      <input
                        type="text"
                        placeholder="Enter zip code"
                        name="zipcode"
                        onChange={inputHandler}
                      />
                      <button type="submit" className="submit">
                        SEARCH <br /> PROVIDER
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src="/assets/imgs/hero/overlay.png"
          alt="overlay"
          className="overlay"
        />
      </section>
      <Sectionone />
      <Sectionfour />
      <Sectionfive show={true} />
      <Sectiontwo />
      <Sectionthree />
      <Modal />
      <Cta />
      <Providersection />
      <Cities />
      <States show={false} />
      <Footer />
    </Fragment>
  );
};

export default Section;
