import { POST_FORM } from "../utils/types";

const initialState = {
  form: null,
};

export const formReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_FORM:
      return {
        ...state,
        form: action.payload,
      };
    default:
      return state;
  }
};
