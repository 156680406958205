import React, { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Helmet } from "react-helmet-async";

const Headerthree = () => {
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [isScroll, setIsScroll] = useState(false);
  const [isSearchIconVisible, setSearchIconVisible] = useState(true);
  const [isSearchCloseVisible, setSearchCloseVisible] = useState(false);
  const [isMenuVisible, setMenuVisible] = useState(false);

  //these states are used for side menu and search input to appear on click

  const handleSearchIconClick = () => {
    setSearchIconVisible(false);
    setSearchCloseVisible(true);
  };

  const handleSearchCloseClick = () => {
    setSearchIconVisible(true);
    setSearchCloseVisible(false);
  };

  const handleMenuClick = () => {
    setMenuVisible(true);
  };

  const handleMenuClose = () => {
    setMenuVisible(false);
  };

  const inputHandler = (e) => {
    setCode(e.target.value);
  };

  useEffect(() => {
    // Function to handle the scroll event
    function handleScroll() {
      // Your code to react to the scroll event goes here
      if (window.scrollY > 100) {
        setIsScroll(true);
      } else {
        setIsScroll(false);
      }
    }

    // Attach the scroll event listener to the window
    window.addEventListener("scroll", handleScroll);

    // Don't forget to remove the listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isScroll]);

  const scrollToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    // window.scrollY = 0;
  };

  //on submit navigate to search component
  const submitHandler = (e) => {
    e.preventDefault();
    navigate(`/search/${code}`);
  };
  return (
    <>
      {/* <!-- Preloader --> */}

      {/* <!-- Scroll to top --> */}
      <button
        id="scroll_top"
        className={isScroll ? "scroll-top showed" : "scroll-top"}
        onClick={scrollToTop}
      >
        <i className="fa-solid fa-arrow-up"></i>
      </button>
      {/* <!-- Header area start --> */}
      <header
        className={isScroll ? "header__area sticky" : "header__area"}
        id="header__sticky"
        // onScroll={scrollHandler}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="header__inner">
                <div className="header__logo">
                  <a href="/">
                    <img
                      src="/assets/imgs/logo/logo.png"
                      alt="Logo"
                      className="logo"
                    />
                    <img
                      src="/assets/imgs/logo/logo-3.png"
                      alt="Logo"
                      className="sticky-logo"
                    />
                  </a>
                </div>
                <div className="header__menu">
                  <div className="main-menu">
                    <ul>
                      <li>
                        <a href="/">Home</a>
                      </li>
                      <li>
                          <a href="/get-plan">Bundle Calculator</a>
                        </li>
                        <li>
                        <a href="#">Service Providers</a>

                        <div class="mega-menu">
                      <ul>
                     
                        <li>Internet Providers</li>
                        <li><a href="providers/spectrum">Spectrum</a></li>
                        <li><a href="providers/xfinity">Xfinity</a></li>
                        <li><a href="providers/centurylink">Century Link</a></li>
                        <li><a href="providers/frontier">Frontier</a></li>
                        <li><a href="providers/at&amp;t">At&t</a></li>
                      </ul>
                      <ul>
                        <li>Tv Providers</li>
                        <li><a href="providers/directv">Direc Tv</a></li>
                       
                      </ul>
                      <ul>
                        <li>Satellite Providers</li>
                        <li><a href="providers/hughesnet">Hughesnet</a></li>
                        <li><a href="providers/viasat">Viasat</a></li>
                     
                      </ul>
                      <ul>
                        <li>Moblie Providers</li>
                        <li><a href="providers/t-mobile">T-Mobile</a></li>
                        <li><a href="providers/verizon">Verizon</a></li>
                     
                      </ul>                       
                    </div>
                    </li>
                      <li>
                        <a href="https://www.broadbandmovers.com/resource">
                          Resource
                        </a>
                      </li>
                      <li>
                        <a href="/contact">Contact</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="header__others">
                  <button
                    className="search-icon"
                    onClick={handleSearchIconClick}
                    style={{
                      display: isSearchIconVisible ? "block" : "none",
                    }}
                  >
                    <i className="fa-solid fa-magnifying-glass"></i>
                  </button>
                  <button
                    className="search-close"
                    onClick={handleSearchCloseClick}
                    style={{
                      display: isSearchCloseVisible ? "block" : "none",
                    }}
                  >
                    <i className="fa-solid fa-xmark"></i>
                  </button>
                  <button className="menu-icon" onClick={handleMenuClick}>
                    <i className="fa-solid fa-bars"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <!-- Header area end --> */}
      <div
        className={
          isSearchCloseVisible ? "header__search showed" : "header__search"
        }
      >
        {isSearchCloseVisible && (
          <form style={{ display: "block" }} onSubmit={submitHandler}>
            <input
              type="text"
              placeholder="zip code"
              name="zipcode"
              onChange={inputHandler}
            />
          </form>
        )}
      </div>
      {/* <!-- Offcanvas area start --> */}
      <div
        className={isMenuVisible ? "offcanvas__area showed" : "offcanvas__area"}
      >
        <div className="offcanvas__inner">
          <div className="offcanvas__top">
            <img src="/assets/imgs/logo/logo.png" alt="Logo" />
            <button id="offcanvas_close" onClick={handleMenuClose}>
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div className="offcanvas__search">
            <form onSubmit={submitHandler}>
              <input
                type="text"
                placeholder="Search..."
                name="zipcode"
                onChange={inputHandler}
              />
            </form>
          </div>
          <div className="offcanvas__menu">
            <div className="offcanvas-menu">
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="/get-plan">Bundle Calculator</a>
                </li>
                <li className="has-dropdown">
                  <a href="https://www.broadbandmovers.com/resource">
                    Resource
                  </a>
                </li>
                <li>
                  <a href="/contact">Contact</a>
                </li>
              </ul>
            </div>
          </div>

          <div className="offcanvas__btm">
            <ul className="footer__location">
              <li>
                <i className="fa-solid fa-phone"></i>
                <a href="tel:+18007126792">(800)-712-6792 (Support)</a>
              </li>
              <li>
                <i className="fa-solid fa-envelope"></i>
                <a href="mailto:info@broadbandmovers.com">
                  info@broadbandmovers.com
                </a>
              </li>
            </ul>
            <div className="separator"></div>
            <ul className="social-media">
              <li>
                <a href="#">
                  <i className="fa-brands fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fa-brands fa-twitter"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fa-brands fa-instagram"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fa-brands fa-pinterest-p"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* <!-- Offcanvas area end --> */}
    </>
  );
};

export default Headerthree;
