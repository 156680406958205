import React, { Fragment, useEffect } from "react";
import { useParams } from "react-router";
import { connect } from "react-redux";
import { getProviderDetails } from "../actions/providerActions";
import Headerthree from "./common/Headerthree";
import Brand from "./common/Brand";
import Footer from "./common/Footer";
import LoadingSpinner from "./ui/LoadingSpinner";
const Plans = (props) => {
  const { name } = useParams();
  let plans;
  let provider;
  useEffect(() => {
    props.getProviderDetails(name);
  }, [name]);

  const { data } = props.provider;
  const { error } = props.error;

  if (data) {
    provider = data[0].provider;
    plans = data.map((item, index) => {
      let description = item.des.replace(/<br>/g, "");
      // let details = item.details.replace(/<br>/g, "");
      return (
        <div
          className="package__type-2 starter wow animate__ animate__fadeInUp animated"
          data-wow-delay="0.2s"
          style={{
            visibility: "visible",
            animationDelay: "0.2s",
            animationName: "fadeInUp",
          }}
          key={index}
        >
          <div className="package__top-2">
            <div className="package__title-wrap">
              <h4 className="package__title">{item.name}</h4>
            </div>
            <div className="package__price-2">
              <span className="monthly_price">
                {item.price.replace(/\/mo/g, "")}
                <span className="type">/month</span>
              </span>
              <span className="yearly_price">
                {item.price.replace(/\/mo/g, "")}
                <span className="type">/month</span>
              </span>
            </div>
          </div>
          <div className="package__feature-2">
            <ul className="list-check-2">{description}</ul>

            <a href="tel:+18007126792" className="cxu-btn-round-dark">
              Get A Free Qoute
            </a>
          </div>
        </div>
      );
    });
  } else if (!data && !error) {
    plans = <LoadingSpinner />;
  }
  return (
    <>
      <Headerthree />
      {/* <!-- Breadcrumb area start --> */}
      <section className=" breadcrumb__areaa breadcrumb__area">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="breadcrumb__content">
                <div className="d-lg-flex justify-content-between align-items-center">
                  <h1 className="px-5">
                    {`Pay Less Enjoy More with ${provider} bundles `}
                  </h1>
                  {provider ? (
                    <img
                      className="breadcrumb__title wow animate__animated animate__fadeInUp px-5"
                      src={`/assets/imgs/brand/${provider}.png`}
                      data-wow-delay="0.2s"
                    />
                  ) : (
                    <img
                      className="breadcrumb__title wow animate__animated animate__fadeInUp"
                      src="/assets/imgs/brand/4.png"
                      data-wow-delay="0.2s"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src="/assets/imgs/hero/overlay.png"
          alt="overlay"
          className="overlay"
          style={{ opacity: "0.3" }}
        />
      </section>
      {/* <!-- Breadcrumb area end --> */}

      {/* <!-- About area start --> */}
      <section className="service__area-2 pb-130 pt-130">
        <div className="events">
          <div className="container">
            <div className="row">
              <div className="package__list">{plans}</div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- About area end --> */}
      <Brand />
      <Footer />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    provider: state.provider,
    error: state.error,
  };
};

export default connect(mapStateToProps, { getProviderDetails })(Plans);
