import axios from "axios";
import { POST_FORM } from "../utils/types";
import { ERROR } from "../utils/types";

export const sendEmail = (formData) => async (dispatch) => {
  try {
    const response = await axios.post(
      "https://server.broadbandmovers.com/submit-form",
      formData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    dispatch({
      type: POST_FORM,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR,
      payload: error,
    });
  }
};
