import { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";
import Breadcrumb from "./components/common/Breadcrumb";
import Headerthree from "./components/common/Headerthree";
import LoadingSpinner from "./components/ui/LoadingSpinner";
import Section from "./components/Section";
import Provider from "./components/Provider";
import States from "./components/States";
import State from "./components/State";
import Plans from "./components/Plans";
// import PrivacyPolicy from "./components/pages/PrivacyPolicy";
const PrivacyPolicy = lazy(() => import("./components/pages/PrivacyPolicy"));
// const Plans = lazy(() => import("./components/Plans"));
// import Contact from "./components/pages/Contact";
const Contact = lazy(() => import("./components/pages/Contact"));
// import Search from "./components/Search";
const Search = lazy(() => import("./components/Search"));
// import NotFound from "./components/ui/NotFound";
const NotFound = lazy(() => import("./components/ui/NotFound"));
// const Section = lazy(() => import("./components/Section"));
// import About from "./components/pages/About";
const About = lazy(() => import("./components/pages/About"));
// const State = lazy(() => import("./components/State"));
// import City from "./components/City";
const City = lazy(() => import("./components/City"));

// const Provider = lazy(() => import("./components/Provider"));
// const States = lazy(() => import("./components/States"));
// import Sectionfive from "./components/Sectionfive";
const Sectionfive = lazy(() => import("./components/Sectionfive"));
const Sectiongetstared = lazy(() => import("./components/Sectiongetstared"));
// import InfoRequest from "./components/pages/InfoRequest";
const InfoRequest = lazy(() => import("./components/pages/InfoRequest"));
// import GetPlan from "./components/GetPlan";
const GetPlan = lazy(() => import("./components/GetPlan"));

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Section />} exact />
          <Route
            path="/about"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <About />
              </Suspense>
            }
            exact
          />
          <Route
            path="/contact"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <Contact />
              </Suspense>
            }
            exact
          />
          <Route path="/providers/:name" element={<Provider />} exact />
          <Route path="/states/:state" element={<State />} exact />
          <Route path="/cities/:city" element={<City />} exact />
          <Route
            path="/search/:zip"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <Search />
              </Suspense>
            }
            exact
          />
          <Route path="/plans/:name" element={<Plans />} exact />
          <Route
            path="/privacy-policy-terms-and-conditions"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <PrivacyPolicy />
              </Suspense>
            }
          />

          <Route
            path="/states"
            element={
              <>
                <Header />
                <Breadcrumb />
                <States show={true} />
                <Footer />
              </>
            }
          />

          <Route
            path="/apply"
            element={
              <>
                <Header />
                <Sectionfive show={false} />
                <Footer />
              </>
            }
            exact
          />
  <Route
            path="/getstarted"
            element={
              <>
                <Header />
                <Sectiongetstared show={false} />
                <Footer />
              </>
            }
            exact
          />


          <Route path="/cities" element={<Navigate to={"/states"} />} exact />
          <Route
            path="*"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <Header />
                <NotFound />
                <Footer />
              </Suspense>
            }
          />
          <Route
            path="/info-request"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <Header />
                <InfoRequest />
                <Footer />
              </Suspense>
            }
            exact
          />
          <Route
            path="/get-plan"
            element={
              <>
                <Headerthree />
                <GetPlan />
                <Footer />
              </>
            }
            exact
          />
        </Routes>
        {/* <Footer /> */}
      </Router>
    </>
  );
}

export default App;
