import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { getAllStates } from "../actions/stateActions";
import { Helmet } from "react-helmet-async";
import LoadingSpinner from "./ui/LoadingSpinner";

const States = (props) => {
  const title = `Broadbandmovers | Find Best ISPs in Any U.S States on One Stop`;
  let states;
  const { data } = props.states;
  const { error } = props.error;

  useEffect(() => {
    props.getAllStates();
  }, []);

  if (data) {
    states = data.map((item, index) => {
      return (
        <li key={index}>
          <a href={`/states/${item.states.toLowerCase()}`}>{item.states}</a>
        </li>
      );
    });
  } else if (!data && !error) {
    states = <LoadingSpinner />;
  }

  return (
    <Fragment>
      <Helmet>
        {props.show === true ? (
          <>
            <title>{title}</title>{" "}
            <meta
              name="description"
              content="Broadband Movers helps you find the best ISPs in all U.S. state. We compare speeds, prices, and customer service to find the best deal for you"
            />
          </>
        ) : null}
        {props.show ? (
          <meta
            name="description"
            content="Broadband Movers helps you find the best ISPs in all U.S. state. We compare speeds, prices, and customer service to find the best deal for you"
          />
        ) : null}

        <link rel="canonical" href={window.location.href} />
      </Helmet>

      {/* <!-- State area start --> */}
      <section className="package__area pt-130">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="sec-title-wrap">
                {/* <h2
                  className="sec-sub-title wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.2s"
                >
                  Offerings provide
                </h2> */}
                <h3
                  className="sec-title wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.4s"
                >
                  Largest Providers <br />
                  <span>in our states.</span>
                </h3>
              </div>
              <div className="col-xxl-12 widget__tags">
                <ul>{states}</ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    states: state.states,
    error: state.error,
  };
};

export default connect(mapStateToProps, { getAllStates })(States);
