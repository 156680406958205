import React, { Fragment } from "react";

const Sectionfour = () => {
  return (
    <Fragment>
      {/* <!-- Process area start --> */}
      <section className="process__area-2 pb-130">
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
              <div className="process__left-2 pt-130 pb-130">
                <h2
                  className="sec-sub-title wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.2s"
                >
                  Work Together
                </h2>
                <h3
                  className="sec-title wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.4s"
                >
                  Best Provider!! How To Investigate?
                </h3>
                <p
                  className="wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.6s"
                >
                  When you type your zip code on Broadbandmovers, we don't just
                  show you coverage options; we also display you company ratings
                  from actual consumers. Our scores measure services by
                  demonstrating how happy their customers are with rates,
                  platforms, rates, and customer service, based on an objective
                  consumer satisfaction survey.{" "}
                </p>
                <a
                  href="/apply"
                  className="cxu-btn-round wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.8s"
                >
                  GET STARTED
                </a>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
              <div className="process__content-2 inherit-right"></div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Process area end --> */}
    </Fragment>
  );
};

export default Sectionfour;
