import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams, useNavigate } from "react-router";
import Headerthree from "./common/Headerthree";
import { connect } from "react-redux";
import { getStateInfo, getTopCities, getCities } from "../actions/stateActions";
import Brand from "./common/Brand";
import Footer from "./common/Footer";
import LoadingSpinner from "./ui/LoadingSpinner";

const State = (props) => {
  const { state } = useParams();
  const navigate = useNavigate();
  const [code, setCode] = useState(null);
  useEffect(() => {
    props.getStateInfo(state);
    props.getTopCities(state);
    props.getCities(state);
  }, []);
  // let stateContent;
  let fiveContent;
  let allCities;
  let stateArr;
  console.log(`rendering...`);
  if (state) {
    stateArr = state.split("-");

    for (let i = 0; i < stateArr.length; i++) {
      stateArr[i] = stateArr[i].charAt(0).toUpperCase() + stateArr[i].slice(1);
    }
  }
  const stateModified = stateArr.join(" ");

  //fetch data to get state info

  const { fiveCities, cities } = props.state;
  const { error } = props.error;

  if (cities) {
    allCities = cities.map((item, index) => {
      return (
        <Fragment key={index}>
          <tr>
            <th scope="row">
              <a href={`/cities/${item.cities.toLowerCase()}`}>{item.cities}</a>
            </th>

            <td>{item.ramd}</td>
            <td>{item.city}</td>
          </tr>
        </Fragment>
      );
    });
  } else if (!cities && !error) {
    allCities = <LoadingSpinner />;
  }

  if (fiveCities) {
    fiveContent = fiveCities.map((item, index) => {
      return (
        <Fragment key={index}>
          <tr>
            <td scope="row">
              <a href={`/cities/${item.cities.toLowerCase()}`}>{item.cities}</a>
            </td>
            <td>{item.ramd}</td>
            <td>{item.city}</td>
          </tr>
        </Fragment>
      );
    });
  } else if (!fiveCities && !error) {
    fiveContent = <LoadingSpinner />;
  }

  const inputHandler = (e) => {
    setCode(e.target.value);
  };

  //on submit navigate to search component
  const submitHandler = (e) => {
    e.preventDefault();
    navigate(`/search/${code}`);
  };

  return (
    <>
      <Helmet>
        <title>{`best internet provider in ${stateModified} | Broadbandmovers`}</title>
        <meta
          name="description"
          content={`Broadbandmovers offers the best internet provider in the state of ${stateModified} through best providers.`}
        />
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="canonical" href={window.location.href} />
        <link
          rel="alternate"
          media="only screen and (max-width: 640px)"
          href={window.location.href}
        />
      </Helmet>
      <Headerthree />
      {/* <!-- Breadcrumb area start --> */}
      <section className=" breadcrumb__areaa breadcrumb__area">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="breadcrumb__content">
                <h1
                  className=" wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.2s"
                >
                  Internet Service Providers in{" "}
                  <span style={{ color: "#ff4904" }}> {stateModified}</span>{" "}
                </h1>
              </div>
            </div>
            <div className="col-md-3">
              <div className="hero__content">
                <div className="newsletter__form">
                  <div
                    className="newsletter wow animate__animated animate__fadeInUp"
                    data-wow-delay="0.4s"
                  >
                    <form onSubmit={submitHandler}>
                      <input
                        type="text"
                        name="zipcode"
                        placeholder="Enter zip code"
                        maxLength="5"
                        onChange={inputHandler}
                        required
                      />

                      <button type="submit" className="submit">
                        SEARCH <br /> PROVIDER
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Breadcrumb area end --> */}
      {/* <!-- About area start --> */}
      <section className="about__area  pb-130 pt-130">
        <div className="events">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <nav
                  id="navbar-example3"
                  className="navbar navbar-light bg-light"
                >
                  <a className="navbar-brand" href="#">
                    Navbar
                  </a>
                  <nav className="nav nav-pills flex-column">
                    <a className="nav-link" href="#item-1">
                      Usage
                    </a>
                    <nav className="nav nav-pills flex-column">
                      <a className="nav-link ml-3 my-1" href="#item-1-1">
                        Internet speed
                      </a>
                      <a className="nav-link ml-3 my-1" href="#item-1-2">
                        Internet Future
                      </a>
                      <a className="nav-link ml-3 my-1" href="#item-1-3">
                        Internet accessibility
                      </a>
                      <a className="nav-link ml-3 my-1" href="#item-1-4">
                        Challenges to internet
                      </a>
                      <a className="nav-link ml-3 my-1" href="#item-1-5">
                        Explore Fast ISP
                      </a>
                    </nav>
                    <a className="nav-link" href="#item-2">
                      Cities
                    </a>
                    <a className="nav-link" href="#item-3">
                      Providers By City
                    </a>
                    <nav className="nav nav-pills flex-column">
                      <a className="nav-link ml-3 my-1" href="#item-3-1">
                        Conclusion
                      </a>
                    </nav>
                  </nav>
                </nav>
                <div className="widget__ads">
                  <a href="tel:+18007126792">
                    <img
                      src="/assets/imgs/portfolio/get-started.jpg"
                      alt="start"
                    />
                  </a>
                </div>
              </div>

              <div className="col-md-8">
                <div
                  className="blogitems"
                  data-spy="scroll"
                  data-target="#navbar-example3"
                  data-offset="0"
                >
                  <h2 id="item-1" className="blog__title-3 plr">
                    INTERNET IN {state}
                  </h2>
                  <p className="blogitems">
                    In {state}, high-speed internet and cable TV services are
                    readily available, catering to a significant portion of the
                    population, while fiber internet access remains limited but
                    promising. The state has also embraced 5G technology,
                    heralding a new era of mobile connectivity. Many providers
                    offer bundle packages, combining internet, cable TV, and
                    home phone services, ensuring people have convenient and
                    diverse options to meet their digital needs.
                  </p>

                  <h3 id="item-1-1" className="blog__title-3 plr">
                    INTERNET SPEED IN {state}
                  </h3>
                  <p className="blogitems">
                    {state}'s largest metropolitan area, enjoys robust internet
                    speeds, exceeding the state's average and highlighting the
                    accessibility of high-speed internet across {state}.
                    Similarly, Mobile, another significant city in the state,
                    offers impressive speeds, further underscoring the
                    prevalence of high-speed internet options in {state} 's
                    urban centers. These fast internet connections in major
                    cities reflect the broader trend of improving digital
                    infrastructure, ensuring that residents have the bandwidth
                    needed for various online activities, from remote work and
                    education to streaming and communication.
                  </p>

                  <h3 id="item-1-2" className="blog__title-3 plr">
                    EMBRACE A CONNECTED FUTURE IN {state}
                  </h3>
                  <p className="blogitems">
                    Our suggested internet service provider and TV bundles in
                    {state} are designed to provide the ultimate convenience and
                    value. As your go-to source for cheap internet provider, we
                    bring you lightning-fast internet speeds that ensure
                    seamless streaming and browsing. With the best TV bundle
                    options available, enjoy a wide range of top-quality
                    channels and entertainment at your fingertips. Elevate your
                    digital experience and enjoy big savings with our suggested
                    unbeatable bundles, tailor-made to meet all your business
                    and entertainment needs. Don't miss out on this incredible
                    opportunity to access fast internet and the best TV bundle
                    in {state}. Trust our chosen internet service provider for
                    affordable, top-notch internet and TV packages, and embark
                    on a journey of limitless connectivity and entertainment.
                  </p>
                  <h3 id="item-1-3" className="blog__title-3 plr">
                    ACCESSIBLE INTERNET OPTIONS IN {state}
                  </h3>
                  <p className="blogitems">
                    In the United States, there are many different kinds of
                    internet services to meet the needs of the large and diverse
                    population. Let's take a closer look at the main types of
                    internet services available throughout the country.
                    <ul>
                      <li>
                        <h4 className="blog__title-4 plr">
                          DSL (Digital Subscriber Line) Internet
                        </h4>
                        <p className="plr">
                          DSL internet utilizes being telephone lines to
                          transmit data. ISP companies give DSL services,
                          particularly in regions where fiber and cable options
                          are limited.
                        </p>
                      </li>
                      <li>
                        <h4 className="blog__title-4 plr">
                          Fiber- Optic Internet
                        </h4>
                        <p className="plr">
                          Fiber-optic internet is known for its speed and trust
                          ability, using thin glass or plastic cables to
                          transmit data via light signals. Numerous providers
                          offer fiber-optic internet in select cities.
                        </p>
                      </li>
                      <li>
                        <h4 className="blog__title-4 plr">
                          Satellite Internet
                        </h4>
                        <p className="plr">
                          Satellite internet is available in remote and rural
                          areas where other wired options may not reach. Some
                          companies give satellite internet services, though it
                          can be more precious and may have data operation
                          limitations.
                        </p>
                      </li>
                      <li>
                        <h4 className="blog__title-4 plr">
                          Fixed Wireless Internet
                        </h4>
                        <p className="plr">
                          Fixed wireless internet is delivered through radio
                          signals and antennas. Some internet service providers
                          offer fixed wireless services in multiple regions,
                          including some rural areas.
                        </p>
                      </li>
                      <li>
                        <h4 className="blog__title-4 plr">
                          5G Mobile Internet
                        </h4>
                        <p className="plr">
                          5G is the latest generation of cellular network
                          technology. 5G offers significantly faster pets than
                          former networks. Some 5G providers are offering home
                          internet service using 5G networks.
                        </p>
                      </li>
                      <li>
                        <h4 className="blog__title-4 plr">
                          Home Phone and Internet Bundles
                        </h4>
                        <p className="plr">
                          Numerous providers offer bundles that combine
                          internet, cable TV, and home phone services, allowing
                          guests to save on multiple services.
                        </p>
                      </li>
                    </ul>
                  </p>
                  <h3 id="item-1-4" className="blog__title-3 plr">
                    FACING CHALLENGES IN {state}
                  </h3>
                  <p className="blogitems">
                    There are significant challenges to internet access in{" "}
                    {state}. One major hurdle is the cost of internet service,
                    with the average monthly price being $50, surpassing the
                    national average of $45. This higher cost can place
                    financial strain on residents, making it difficult for some
                    to afford reliable internet access. Another major obstacle
                    is the limited competition among internet service providers
                    in various regions of the state. With only one or two
                    providers available in many areas, customers have fewer
                    options, leading to potential issues with pricing and
                    internet speeds.
                  </p>
                  <h3 id="item-1-5" className="blog__title-3 plr">
                    EXPLORE YOUR LIGHTNING FAST ISP
                  </h3>
                  <p className="blogitems">
                    Experience the pinnacle of internet service in {state}
                    through our platform. Delve into our handpicked collection
                    of exceptional providers, including renowned industry names
                    like Frontier, Spectrum, AT&T, Verizon, Hughes Net,
                    CenturyLink, Earthlink, Xfinity, etc. We stand as your
                    dependable intermediary, streamlining your search for the
                    best internet providers. Leave behind the frustration of
                    endless searches and welcome a world of seamless browsing,
                    streaming, and online exploration.
                  </p>
                  <div className="row justify-content-center text-center">
                    <div className="col-12 col-md-10">
                      <table className="table table-hover table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">Provider</th>
                            <th scope="col">Speed</th>
                            <th scope="col">Price</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Spectrum</td>
                            <td>1 Gbps</td>
                            <td>
                              <strong>$74.99</strong>
                            </td>
                            <td>
                              <a href="tel:+18007126792" className="plan-btn">
                                Call now
                              </a>

                              <a href="/apply" className="plan-btn-dark">
                                Queue
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>T-Mobile</td>
                            <td>100 Mbps</td>
                            <td>
                              <strong>$24.99</strong>
                            </td>
                            <td>
                              <a href="tel:+18007126792" className="plan-btn">
                                Call now
                              </a>

                              <a href="/apply" className="plan-btn-dark">
                                Queue
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>AT&T</td>
                            <td>50 Mbps</td>
                            <td>
                              <strong>$24.99</strong>
                            </td>
                            <td>
                              <a href="tel:+18007126792" className="plan-btn">
                                Call now
                              </a>

                              <a href="/apply" className="plan-btn-dark">
                                Queue
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>HughesNet</td>
                            <td>25 Mbps</td>
                            <td>
                              <strong>$19.99</strong>
                            </td>
                            <td>
                              <a href="tel:+18007126792" className="plan-btn">
                                Call now
                              </a>

                              <a href="/apply" className="plan-btn-dark">
                                Queue
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>Verizon</td>
                            <td>1 Gbps</td>
                            <td>
                              <strong>$75.99</strong>
                            </td>
                            <td>
                              <a href="tel:+18007126792" className="plan-btn">
                                Call now
                              </a>

                              <a href="/apply" className="plan-btn-dark">
                                Queue
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/* {stateContent} */}
                  <h4 id="item-2" className="blog__title-3 plr">
                    Internet Speed in Top {stateModified} Cities
                  </h4>

                  <table className="table table-hover table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">City</th>
                        <th scope="col">Avg. Download Speed</th>
                        <th scope="col">No. of Providers</th>
                      </tr>
                    </thead>
                    <tbody>{fiveContent}</tbody>
                  </table>

                  <h4 id="item-3">
                    Internet Providers By City in {stateModified}
                  </h4>
                  <table className="table table-hover table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">City</th>
                        <th scope="col">Avg. Download Speed</th>
                        <th scope="col">No. of Providers</th>
                      </tr>
                    </thead>
                    <tbody>{allCities}</tbody>
                  </table>
                  <h4 id="item-3-1" className="blog__title-3 plr">
                    Conclusion
                  </h4>
                  <p className="blogitems">
                    Internet access is essential for economic development and
                    social inclusion. {state} is working to improve internet
                    access in the state, but there are still challenges to
                    overcome. By working to increase competition among internet
                    providers and reduce the cost of internet service,
                    {state} can make the internet more accessible to all people.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- About area end -->  */}
      <Brand />
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    state: state.states,
    error: state.error,
  };
};

export default connect(mapStateToProps, {
  getStateInfo,
  getTopCities,
  getCities,
})(State);
