export const FETCH_PROVIDER = "FETCH_PROVIDER";
export const FETCH_PROVIDER_CITY = "FETCH_PROVIDER_CITY";
export const FETCH_PROVIDER_ZIP = "FETCH_PROVIDER_ZIP";
export const SET_LOADING = "SET_LOADING";
export const FETCH_STATE = "FETCH_STATE";
export const FETCH_FIVE_CITY = "FETCH_FIVE_CITY";
export const FETCH_CITIES = "FETCH_CITIES";
export const FETCH_STATE_CITY = "FETCH_STATE_CITY";
export const FETCH_SPEED = "FETCH_SPEED";
export const POST_FORM = "POST_FORM";
export const ERROR = "ERROR";
