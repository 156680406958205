import { combineReducers, configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import { providerReducer } from "./reducers/providerReducer";
import { stateReducer } from "./reducers/stateReducer";
import { errorReducer } from "./reducers/errorReducer";
import { formReducer } from "./reducers/formReducer";
import { speedReducer } from "./reducers/speedReducer";

const rootReducer = combineReducers({
  provider: providerReducer,
  states: stateReducer,
  error: errorReducer,
  form: formReducer,
  speed: speedReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
});
