import React, { Fragment } from "react";

const Sectiontwo = () => {
  return (
    <Fragment>
      {/* <!-- About area start --> */}
      <section className="about__area-2 pt-130 pb-130">
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
              <div className="about__left-3">
                <h2
                  className="exp wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.2s"
                >
                  25
                </h2>
                <h2
                  className="exp-title  wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.4s"
                >
                  fastest internet service provider
                </h2>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
              <div className="about__right-2">
                <div className="about__right-inner-2">
                  <h3
                    className="sec-title wow animate__animated animate__fadeInUp"
                    data-wow-delay="0.4s"
                  >
                    Searching through zip code for comparison of the cable and
                    internet provider
                  </h3>
                  <p
                    className="wow animate__animated animate__fadeInUp text-justify"
                    data-wow-delay="0.5s"
                  >
                    At Broadbandmovers.com, we understand the importance of high
                    efficiency internet performance in today's digital world. We
                    are dedicated to suggest you the best TV and internet
                    bundles from the U.S.A's leading TV and Internet Service
                    Providers, in your region to bring you the best coverage
                    offerings available. By simply entering your zip code, you
                    can easily explore the options provided by various Internet
                    Service Providers (ISPs) and Television Providers in your
                    city. Our platform allows you to compare cable,
                    telecommunications, and Satellite Providers side by side,
                    empowering you to make an informed decision. Whether you're
                    looking for the best Internet & TV bundles, the fastest
                    internet providers, or the top fiber TV and internet cable
                    provider in the US, we've got you covered. We also offer
                    information on the best satellite TV bundles and the best
                    Wi-Fi companies in your area. We strive to simplify your
                    search for the ideal TV and internet bundles, ensuring you
                    have access to the best options tailored to your needs.
                  </p>
                  <ul
                    className="list wow animate__animated animate__fadeInUp"
                    data-wow-delay="0.6s"
                  >
                    <li>
                      <div className="icon">
                        <img src="/assets/imgs/feature/1.png" alt="" />
                      </div>
                      <span>
                        Best Live <br />
                        TV Streaming Service{" "}
                      </span>
                    </li>
                    <li>
                      <div className="icon">
                        <img src="/assets/imgs/feature/4.png" alt="" />
                      </div>
                      <span>
                        Best Satellite <br />
                        and Fiber Internet Providers
                      </span>
                    </li>
                  </ul>
                  <a
                    href="/about"
                    className="cxu-btn-round wow animate__animated animate__fadeInUp"
                    data-wow-delay="0.8s"
                  >
                    READ MORE
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- About area end --> */}
    </Fragment>
  );
};

export default Sectiontwo;
